import React from 'react';
import { Router } from '@reach/router';
import { Container } from 'reactstrap';

import logo from './images/Logo_Dental-PostPrimary_Color-150px.png';
import IntakeForm from './IntakeForm';

function App() {
  return (
    <div className="mt-2 pb-3">
      <header className="App-header">
        <div className="text-center">
          <img src={logo} alt="DentalPost logo" />
        </div>
      </header>
      <Container tag="main">
        <Router>
          <IntakeForm path=":leadSource" />
          <IntakeForm default path="/" />
        </Router>
      </Container>
    </div>
  );
}

export default App;
